import { render } from "./RecentNFT.vue?vue&type=template&id=538ff4fc&scoped=true"
import script from "./RecentNFT.vue?vue&type=script&lang=js"
export * from "./RecentNFT.vue?vue&type=script&lang=js"

import "./RecentNFT.vue?vue&type=style&index=0&id=538ff4fc&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-538ff4fc"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QCardSection,QAvatar,QIcon,QBtn});
