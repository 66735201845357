<template>
  <div class="row">
    <div
      v-for="k in allnfts"
      :key="k.id"
      class="col-xs-12 col-sm-6 col-md-3 col-lg-3"
    >
      <q-card class="q-ma-xs my-card">
        <a :href="'/nft/' + k.id">
          <q-img
            class="my-card-img q-pa-md"
            ratio="1"
            :src="k.data.image"
            :alt="k.data.description"
          >
            <q-img
              v-if="k.total - k.token_sum <= 0"
              class="absolute-center"
              src="@/assets/soldout.png"
            />
          </q-img>
        </a>
        <q-card-section>
          <div class="my-nft-name">{{ k.data.name }}</div>
          <!-- <div class="text-body2">
                  {{ pdate(k.created_at) }}
                </div> -->
          <div class="row q-mt-md items-center">
            <div class="col-3">
              <q-avatar rounded style="border-radius: 18px">
                <img :src="k.uowner.avatar" />
              </q-avatar>
            </div>
            <div class="col q-ml-md truncate">
              <span color="grey ">Creator </span> <br />
              <span>{{ k.uowner.nickname }}</span>
            </div>
            <div class="col">
              <span
                class="
                  bg-secondary
                  q-pl-xs q-pr-xs q-pt-none q-pb-none
                  float-right
                "
                style="border-radius: 5px"
              >
                <q-icon color="red" size="16px" name="favorite" />
                <span
                  class="text-weight-bold text-white q-ml-xs"
                  style="font-size: 12px"
                  >{{ k.likes }}</span
                >
              </span>
            </div>
          </div>
          <hr style="border: 1px solid #14141f" />
          <div class="row">
            <div class="col text-left">
              <span class="text-grey">{{ $t("price") }}</span>
              <br />
              <span class="text-bold">${{ k.data.price }} </span>
            </div>
            <div class="col text-center">
              <span class="text-grey">{{ $t("total") }}</span>
              <br />
              <span class="text-bold">{{ k.total }}</span>
            </div>
            <div class="col text-right">
              <span class="text-grey">{{ $t("remains") }}</span>
              <br />
              <span class="text-bold">{{
                k.total - k.token_sum > 0 ? k.total - k.token_sum : 0
              }}</span>
            </div>
          </div>
        </q-card-section>

        <q-img
          v-if="k.data.price == 0"
          width="4vw"
          class="absolute-top-right"
          src="@/assets/free.png"
        />
      </q-card>
    </div>
  </div>
  <div class="q-ma-md q-pa-md flex justify-center">
    <q-btn
      color="primary q-pa-md text-bold q-pl-xl q-pr-xl"
      style="border: 1px white solid"
      rounded
      size="md"
      @click="this.$router.push('/discover')"
    >
      Load More
    </q-btn>
  </div>
</template>
<style lang="sass" scoped>
.my-card
  border-radius: 15px
  padding: 10px
  margin-bottom: 10px
  .my-nft-name
    font-size: 18px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .my-card-img
    border-radius: 15px
.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis    
</style>

<script>
import router from "@/router";
import { supabase as s } from "../supabase";
import { ref, onBeforeMount } from "vue";
import { likeit } from "../web3";

export default {
  name: "RecentNFT",
  props: {
    count: {
      type: Number,
      default: 12,
    },
  },
  setup(props) {
    let allnfts = ref(null);

    onBeforeMount(async () => {
      {
        let { data: nfts, error } = await s
          .from("v_nfts")
          .select("*,uowner:uowner(*)")
          .gt("flag", -50)
          .neq("data->>image", "")
          .limit(props.count)
          .order("created_at", { ascending: false });
        if (error) console.log(error);
        console.log(nfts);

        allnfts.value = nfts;
      }
    });

    return {
      allnfts,
      likeit,
    };
  },

  methods: {
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
  },
};
</script>
